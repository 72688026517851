import axios from "axios";

const staffUrl = "/v2/api/staff";

class StaffService {
    //Get all staff
    static getStaffs(pagingOpts) {
        return new Promise(async (resolve, reject) => {
            try {
                pagingOpts = pagingOpts || {};

                let url = [
                    staffUrl,
                    `?page=${pagingOpts.page || 1}`,
                    `&limit=${pagingOpts.limit || 50}`,
                    `&orderBy=${pagingOpts.orderBy || "name"}`,
                    `&order=${pagingOpts.order || "asc"}`,
                ].join("");

                let result = await axios.get(url);
                let pagingData = result.data;

                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Search Staff
    static searchStaff(searchTerm, pagingOpts) {
        return new Promise(async (resolve, reject) => {
            try {
                pagingOpts = pagingOpts || {};
                let url = [
                    staffUrl,
                    `?searchTerm=${searchTerm}`,
                    `&page=${pagingOpts.page || 1}`,
                    `&limit=${pagingOpts.limit || 50}`,
                    `&orderBy=${pagingOpts.orderBy || "created_at"}`,
                    `&order=${pagingOpts.order || "desc"}`,
                    `&select=${pagingOpts.select || ""}`,
                    `&staff_email=${pagingOpts.staff_email || ""}`,
                ].join("");
                let result = await axios.get(url);

                let pagingData = result.data;

                resolve(pagingData);
            } catch (error) {
                console.log("ERROR: ", error);
                reject(error.response);
            }
        });
    }

    // Get Staff by Id
    static getStaffById(staffId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}?expand=agent`;
                let result = await axios.get(url);
                if (result.data) {
                    if (result.data.dob) {
                        result.data.dob = new Date(result.data.dob);
                    }
                    if (result.data.doj) {
                        result.data.doj = new Date(result.data.doj);
                    }
                }
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Get Staff by Email
    static getStaffByEmail(staffEmail) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/email/${staffEmail}`;
                let result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Create Staff
    static createStaff(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.post(staffUrl, data);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Update Staff
    static updateStaff(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${data.staff_id}`;
                let result = await axios.put(url, data);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    //Remove Staff

    static deleteStaff(staffId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}`;
                let result = await axios.delete(url);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Create Emergency Contact
    static createEmergencyContact(staffId, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}/emergency`;
                let result = await axios.post(url, data);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Update Emergency Contact
    static updateEmergencyContact(staffId, id, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}/emergency/${id}`;
                let result = await axios.put(url, data);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Remove Emergency Contact
    static deleteEmergency(staffId, id) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}/emergency/${id}`;
                let result = await axios.delete(url);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Uploads the document
    static async createStaffDocument(staffId, document, isSignature = false) {

        return new Promise(async (resolve, reject) => {
            try {
                let docToUpload;
                if (isSignature) {
                    docToUpload = {
                        ...document,
                        filename: 'signature', // Add filename as 'signature' for signatures
                    };
                } else {
                    docToUpload = {
                        filename: document.file[0].name,
                        content_type: document.file[0].type,
                        visibility: document.visibility,
                        category: document.category,
                        name: document.name,
                        description: document.description,
                        document_number: document.document_number,
                        is_published: true,
                    };
                }
                console.group("[DocumentService] uploadDocument");
                console.log("File is:", isSignature ? document.file : document.file[0]);
                console.log("Document is:", docToUpload);
                console.groupEnd();
                let result = await axios.post(
                    `${staffUrl}/${staffId}/documents`,
                    docToUpload,
                );
                console.log(result);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static async getStaffDocumentsByDocRole(staffId, documentRole) {
        try {
            const staffDocumentUrl = `${staffUrl}/${staffId}/documents/signature`;

            const staffDocumentUrlWithRole = documentRole ? `${staffDocumentUrl}?document_role=${encodeURIComponent(documentRole)}` : staffDocumentUrl;
            // Fetch the documents
            let result = await axios.get(staffDocumentUrlWithRole);
            return result.data;
        } catch (error) {
            console.error("Error fetching job sheet documents:", error);
            throw error;
        }
    }

    // Get Staff by Id
    static getStaffDocuments(staffId) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(`${staffUrl}/${staffId}/documents`);

                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateStaffDocument(staffId, documentId, body) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}/documents/${documentId}`;
                let result = await axios.put(url, body);

                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteStaffDocumentById(staffId, documentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${staffUrl}/${staffId}/documents/${documentId}`;
                let result = await axios.delete(url);

                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Get Document by Id
    static getDocumentById(staffId, documentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(
                    `${staffUrl}/${staffId}/documents/${documentId}`,
                );

                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default StaffService;
