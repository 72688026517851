import decode from "jwt-decode";
import axios from "axios";
import auth0 from "auth0-js";
import store from "@/store";
import staffService from "@/services/staffService";

import {
    ACCESS_TOKEN_KEY,
    ID_TOKEN_KEY,
    AUTH_AUDIENCE,
    AUTH_DOMAIN,
    AUTH_CLIENT_ID
} from "../utils/constants";

let auth = new auth0.WebAuth({
    clientID: AUTH_CLIENT_ID,
    domain: AUTH_DOMAIN,
    audience: AUTH_AUDIENCE,
    responseType: "token id_token",
    scope: "openid profile email"
});

class AuthService {
    static permissionCheck(permissions) {
        return store.state.userPermissions.some(r => permissions.includes(r));
        // return store.state.userPermissions.includes(permission);
    }

    static roleCheck(role) {
        return store.state.userRoles.includes(role);
    }

    // Login
    static login(username, password) {
        auth.authorize({
            redirectUri: `${window.location.origin}/authcallback`
        });
    }

    // Logout
    static logout() {
        clearIdToken();
        clearAccessToken();
        auth.logout({
            returnTo: `${window.location.origin}/login`
        });
    }

    static refreshSession() {
        return new Promise((resolve, reject) => {
            auth.checkSession(
                {
                    redirectUri: `${window.location.origin}/authcallback`
                },
                (error, authResult) => {
                    if (error) {
                        console.error("Error refreshing token", error);
                        reject(error);
                    } else {
                        let accessToken = authResult.accessToken;
                        let idToken = authResult.idToken;

                        // localStorage.setItem(ID_TOKEN_KEY, idToken)
                        store.dispatch("setUserIdToken", idToken);
                        // localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
                        store.dispatch("setUserAccessToken", accessToken);
                        axios.defaults.headers.common[
                            "Authorization"
                            ] = `Bearer ${accessToken}`;

                        resolve(authResult);
                    }
                }
            );
        });
    }

    static getIdToken() {
        // return localStorage.getItem(ID_TOKEN_KEY)
        return store.state.userIdToken;
    }

    static getAccessToken() {
        // return localStorage.getItem(ACCESS_TOKEN_KEY)
        return store.state.userAccessToken;
    }

    // Get and store access_token in local storage
    static setAccessToken() {
        let accessToken = getParameterByName("access_token");
        // localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
        store.dispatch("setUserAccessToken", accessToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        console.log("Access Token", accessToken);
    }

    // Get and store id_token in local storage
    static setIdToken() {
        let idToken = getParameterByName("id_token");
        store.dispatch("setUserIdToken", idToken);
    }

    static isLoggedIn() {
        const idToken = this.getIdToken();
        return !!idToken && !isTokenExpired(idToken);
    }

    static isAccessTokenExpired() {
        const accessToken = this.getAccessToken();
        return !accessToken || isTokenExpired(accessToken);
    }

    static getUserInfo() {
        if (this.isLoggedIn()) {
            return decode(this.getIdToken());
        }
    }

    static storePermissions() {
        if (this.isLoggedIn()) {
            let decodedToken = decode(this.getAccessToken());
            store.dispatch("storeUserPermissions", decodedToken.permissions);
            debugger;
            store.dispatch(
                "storeUserRoles",
                decodedToken["https://boston-internal/user_profile"].roles
            );
            const email = decodedToken["https://boston-internal/user_profile"].email;
            store.dispatch("storeUserEmail", email);
            staffService.getStaffByEmail(email).then(response => {
                store.dispatch("storeUserId", response.staff_id);
                store.dispatch("storeUser", response);
            });
        }
    }
}

export default AuthService;

function clearIdToken() {
    // localStorage.removeItem(ID_TOKEN_KEY)
    store.dispatch("setUserIdToken", null);
}

function clearAccessToken() {
    // localStorage.removeItem(ACCESS_TOKEN_KEY)
    store.dispatch("setUserAccessToken", null);
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
    let match = RegExp("[#&]" + name + "=([^&]*)").exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}
