import axios from "axios";

const contactUrl = "/v2/api/contacts";
const equipmentUrl = "/v2/api/equipment";

class ContactService {
  // Get all Contacts
  static getContacts(pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          contactUrl,
          `?page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "name"}`,
          `&order=${pagingOpts.order || "asc"}`,
        ].join("");

        let result = await axios.get(url);
        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
          if (c.people && c.people.length > 0) {
            c.default_people =
              c.people.find((ppl) => ppl.is_default) || c.people[0];
          }
        });

        resolve(pagingData);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Contact by Id
  static getContactById(contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}?expand=agent`;
        let result = await axios.get(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static findContactByName(name) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}?name=${name}`;
        let result = await axios.get(url);

        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
        });

        resolve(pagingData);
        /* resolve(result.data); */
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Contact
  static searchContact(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          contactUrl,
          `?searchTerm=${encodeURIComponent(searchTerm)}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
          if (c.people && c.people.length > 0) {
            c.default_people =
                c.people.find((ppl) => ppl.is_default) || c.people[0];
          }
        });

        resolve(pagingData);
        //resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Lookup Contact
  static lookupContact(searchTerm) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${contactUrl}?searchTerm=${encodeURIComponent(
            searchTerm
          )}&select=contact_id,name&limit=100`
        );

        resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  static searchContactsWithType(queryParams) {
    return new Promise(async (resolve, reject) => {
      try {
        const queryString = Object.keys(queryParams).map(key => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
        }).join('&');

        let result = await axios.get(`${contactUrl}/search?${queryString}`);

        resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  static createPerson(person) {}

  // Get Equipment by Owner
  static getEquipment(contactId, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          equipmentUrl,
          `?ownerId=${contactId}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Documents
  static getDocuments(contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/documents`;
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getIvyClients(vtmsOrgId, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(vtmsOrgId);
        let url = [
          `/v2/api/vtms/clients?orgId=${vtmsOrgId}`,
          `&page=${pagingOpts.currentPage || 1}`,
          `&limit=${pagingOpts.pageSize || 50}`,
          `&orderBy=${pagingOpts.field || "activated_at"}`,
          `&order=${pagingOpts.isAsc ? "asc" : "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static createContact(contact) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}`;
        let result = await axios.post(url, contact);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateContact(contactId, contact) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}`;
        let result = await axios.put(url, contact);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }


  static createAddress( address,contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/addresses`;
        let result = await axios.post(url, address);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateAddress(contactId, addressId, address) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/addresses/${addressId}`;
        let result = await axios.put(url, address);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteAddress(contactId, addressId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/addresses/${addressId}`;
        let result = await axios.delete(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static createPerson(contactId, person) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/people`;
        let result = await axios.post(url, person);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updatePerson(contactId, personId, person) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/people/${personId}`;
        let result = await axios.put(url, person);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deletePerson(contactId, personId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/people/${personId}`;
        let result = await axios.delete(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static async createContactDocument(contactId, document, isSignature = false) {
    return new Promise(async (resolve, reject) => {
      try {
        let docToUpload;

        if (isSignature) {
          docToUpload = {
            ...document,
            filename: 'signature', // Add filename as 'signature' for signatures
          };
        } else {
          docToUpload = {
            filename: document.file[0].name,
            content_type: document.file[0].type,
            visibility: document.visibility,
            category: document.category,
            name: document.name,
            description: document.description,
            document_number: document.document_number,
            is_published: true,
          };
        }

        console.group("[DocumentService] uploadDocument for Contact");
        console.log("File is:", isSignature ? document.file : document.file[0]);
        console.log("Document is:", docToUpload);
        console.groupEnd();

        let result = await axios.post(
            `${contactUrl}/${contactId}/documents`,
            docToUpload
        );

        console.log(result);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }



  // Get Documents by Contact Id
  static getContactDocuments(contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${contactUrl}/${contactId}/documents`);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

// Update Document by Contact Id and Document Id
  static updateContactDocument(contactId, documentId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/documents/${documentId}`;
        let result = await axios.put(url, body);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

// Delete Document by Contact Id and Document Id
  static deleteContactDocumentById(contactId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/documents/${documentId}`;
        let result = await axios.delete(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

// Get Document by Contact Id and Document Id
  static getDocumentById(contactId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
            `${contactUrl}/${contactId}/documents/${documentId}`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static async generateContactReport(contactId, reportType) {
    try {
      const url = `${contactUrl}/${contactId}/report/${reportType}`;
      const response = await axios.post(url, {}, { responseType: 'blob' });
      return response.data;
    } catch (error) {
      throw new Error(error.response ? error.response.data : error.message);
    }
  }





}

export default ContactService;
