export const ACCESS_TOKEN_KEY = "authToken";
export const ID_TOKEN_KEY = "idToken";
export const AUTH_AUDIENCE = "https://boston-internal-sw/";
export const AUTH_DOMAIN = "internalsw-bostonequipment.eu.auth0.com";
export const AUTH_CLIENT_ID = "zHgLPuWvrrophgr4gbfYidn8WShbOfaS";
export const COUNTRIES = [
  "Afganistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Ter",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Canary Islands",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Channel Islands",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Island",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote DIvoire",
  "Croatia",
  "Cuba",
  "Curaco",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Ter",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Hawaii",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "India",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea Sout",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malaysia",
  "Malawi",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Midway Islands",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Nambia",
  "Nauru",
  "Nepal",
  "Netherland Antilles",
  "Netherlands",
  "Nevis",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau Island",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Phillipines",
  "Pitcairn Island",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Montenegro",
  "Republic of Serbia",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "St Barthelemy",
  "St Eustatius",
  "St Helena",
  "St Kitts-Nevis",
  "St Lucia",
  "St Maarten",
  "St Pierre & Miquelon",
  "St Vincent & Grenadines",
  "Saipan",
  "Samoa",
  "Samoa American",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tahiti",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Is",
  "Tuvalu",
  "Uganda",
  "United Kingdom",
  "Ukraine",
  "United Arab Erimates",
  "United States of America",
  "Uraguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (Brit)",
  "Virgin Islands (USA)",
  "Wake Island",
  "Wallis & Futana Is",
  "Yemen",
  "Zaire",
  "Zambia",
  "Zimbabwe",
];

export const BANKHOLIDAYS = [
  {
    title: "New Year’s Day",
    date: "2017-01-02",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2017-04-14",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2017-04-17",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2017-05-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2017-05-29",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2017-08-28",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2017-12-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2017-12-26",
    notes: "",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2018-01-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2018-03-30",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2018-04-02",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2018-05-07",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2018-05-28",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2018-08-27",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2018-12-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2018-12-26",
    notes: "",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2019-01-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2019-04-19",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2019-04-22",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2019-05-06",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2019-05-27",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2019-08-26",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2019-12-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2019-12-26",
    notes: "",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2020-01-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2020-04-10",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2020-04-13",
    notes: "",
    bunting: false,
  },
  {
    title: "Early May bank holiday (VE day)",
    date: "2020-05-08",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2020-05-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2020-08-31",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2020-12-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2020-12-28",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2021-01-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2021-04-02",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2021-04-05",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2021-05-03",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2021-05-31",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2021-08-30",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2021-12-27",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2021-12-28",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2022-01-03",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2022-04-15",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2022-04-18",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2022-05-02",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2022-06-02",
    notes: "",
    bunting: true,
  },
  {
    title: "Platinum Jubilee bank holiday",
    date: "2022-06-03",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2022-08-29",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2022-12-26",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2022-12-27",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "New Year’s Day",
    date: "2023-01-02",
    notes: "Substitute day",
    bunting: true,
  },
  {
    title: "Good Friday",
    date: "2023-04-07",
    notes: "",
    bunting: false,
  },
  {
    title: "Easter Monday",
    date: "2023-04-10",
    notes: "",
    bunting: true,
  },
  {
    title: "Early May bank holiday",
    date: "2023-05-01",
    notes: "",
    bunting: true,
  },
  {
    title: "Spring bank holiday",
    date: "2023-05-29",
    notes: "",
    bunting: true,
  },
  {
    title: "Summer bank holiday",
    date: "2023-08-28",
    notes: "",
    bunting: true,
  },
  {
    title: "Christmas Day",
    date: "2023-12-25",
    notes: "",
    bunting: true,
  },
  {
    title: "Boxing Day",
    date: "2023-12-26",
    notes: "",
    bunting: true,
  },
];
export const DEPARTMENTS = [
  "Administration",
  "Accounts",
  "Despatch",
  "Development",
  "Engineers",
  "Management",
  "Marketing",
  "Purchasing",
  "Repairs",
  "Sales",
  "Service",
  "Technical Support",
  "Warehouse",
];
export const LeaveAllowance = {
  full_time: [
    {
      leave_type: "Absent",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Annual Leave",
      total_days: 20,
      available_days: 20,
    },
    {
      leave_type: "Compassionate Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Paternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Maternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Sick Leave",
      total_days: 0,
      available_days: 0,
    },
  ],
  part_time_4: [
    {
      leave_type: "Absent",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Annual Leave",
      total_days: 16,
      available_days: 16,
    },
    {
      leave_type: "Compassionate Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Paternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Maternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Sick Leave",
      total_days: 0,
      available_days: 0,
    },
  ],
  part_time_3: [
    {
      leave_type: "Absent",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Annual Leave",
      total_days: 12,
      available_days: 12,
    },
    {
      leave_type: "Compassionate Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Paternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Maternity Leave",
      total_days: 0,
      available_days: 0,
    },
    {
      leave_type: "Statutory Sick Leave",
      total_days: 0,
      available_days: 0,
    },
  ],
};

export const PRODUCT_CODE_JSON_URL =
  "https://bostoninternal.blob.core.windows.net/resources/configuration/product_code.config.json?sv=2023-01-03&st=2023-12-18T15%3A55%3A55Z&se=2033-12-19T15%3A55%3A00Z&sr=b&sp=r&sig=22u0UFlslVIY1WEE7MRaiI5gzaFIk%2F3IkSMRWqsTv64%3D";
